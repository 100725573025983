
  import React from 'react';
  import COMPONENTS from '../../components';
  import { get } from 'lodash';
  import PageLayout from '../../layout';
  import { graphql } from 'gatsby';

  class Page extends React.Component {
    state = {
      data: [],
    };

    componentDidMount() {
      let slices;

      const data = get(this, 'props.data.faqJson');

      if (data) {
        slices = Object.entries(data).map(([key, content]) => ({
          componentName: key,
          ...content,
        }));

        slices.sort(
          ({ position: positionA }, { position: positionB }) =>
            positionA - positionB
        );

        this.setState({
          data: slices,
        });
      }
    }

    render() {
      return (
        <PageLayout>
          {get(this, 'state.data').map(({ componentName, data }, index) => {
            const ComponentType = COMPONENTS[componentName];
            return <ComponentType key={index} {...data} />;
          })}
        </PageLayout>
      );
    }
  }

  export default Page;

  export const pageQuery = graphql`
    query {
      faqJson {
        
      
      
      Header0 {
        position
        componentName
        data {
           title type showRedLine
        }
      }
    
      CTAGrid1 {
        position
        componentName
        data {
           content {  title text button {  link text } }
        }
      }
    
      }
    }
  `